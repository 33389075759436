<template>
	<v-container class="student__courses">
		<v-row>
			<v-col>
				<StudentCourseList
					v-for="course in displayedCourses.slice(0, limit)"
					:key="course.id"
					:courseId="course.id"
					:image="course.images"
					:name="course.title"
					:dateStart="course.created_at"
					:dateComplete="course.completed_at"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="d-flex flex-column align-center">
				<v-btn
					v-if="publishedCourses.length > limit"
					class="admin-primary-button secondary-contrast-background"
					@click="loadMoreCourses"
					>Load More</v-btn
				>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters } from "vuex";
import StudentCourseList from "../../components/StudentCourseList.vue";

export default {
	components: { StudentCourseList },
	name: "StudentCoursesNewCourses",
	props: ["searchText"],

	computed: {
		...mapGetters(["publishedCourses"]),

		newest() {
			const day = 2;
			return this.publishedCourses.filter(function (item) {
				if (
					Math.ceil(
						Number(new Date() - new Date(item.created_at)) /
							1000 /
							60 /
							60 /
							24
					) > day
				) {
					return item;
				}
			});
		},

		displayedCourses() {
			return this.newest.filter(el =>
				String(el.title).includes(this.searchText)
			);
		}
	},

	data() {
		return {
			limit: 2
		};
	},
	methods: {
		loadMoreCourses() {
			this.limit = this.publishedCourses.length;
		}
	}
};
</script>

<style></style>
